<template>
  <div class="tab" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
    <img
      src="../assets/home/logo.png"
      alt=""
      style="width: 0.8rem; height: 1.5rem; margin-bottom: 0.59rem"
    />

    <div :class="data == 1 ? 'itemed item' : 'item'" @click="clicktilte(1)">
      <img src="../assets/home/1.png" alt="" style="" />
      <div class="title" style="display: none">首页</div>
    </div>
    <!-- <router-link :class="data == 2 ? 'itemed item' : 'item'" :key="$route.fullPath" to="/tempView"> -->
    <div :class="data == 2 ? 'itemed item' : 'item'" @click="clicktilte(2)">
      <img src="../assets/home/2.png" alt="" style="" />
      <div class="title" style="display: none">模板网站</div>
    </div>
    <!-- </router-link> -->
    <div :class="data == 3 ? 'itemed item' : 'item'" @click="clicktilte(3)">
      <img src="../assets/home/3.png" alt="" style="" />
      <div class="title" style="display: none">产品介绍</div>
    </div>
    <div :class="data == 4 ? 'itemed item' : 'item'" @click="clicktilte(4)">
      <img src="../assets/home/4.png" alt="" style="" />
      <div class="title" style="display: none">关于我们</div>
    </div>
    <div :class="data == 5 ? 'itemed item' : 'item'" @click="clicktilte(5)">
      <img src="../assets/home/5.png" alt="" style="" />
      <div class="title" style="display: none">新闻动态</div>
    </div>
    <div :class="data == 6 ? 'itemed item' : 'item'" @click="clicktilte(6)">
      <img src="../assets/home/6.png" alt="" style="" />
      <div class="title" style="display: none">帮助中心</div>
    </div>
    <div :class="data == 7 ? 'itemed item' : 'item'" @click="clicktilte(7)">
      <img src="../assets/home/7.png" alt="" style="" />
      <div class="title" style="display: none">联系我们</div>
    </div>
    <div :class="data == 8 ? 'itemed item' : 'item'" @click="clicktilte(8)">
      <img src="../assets/home/8.png" alt="" style="" />
      <div class="title" style="display: none">中联优谷</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      show: "none",
    };
  },
  mounted() {
    console.log(this.data);
  },
  methods: {
    handleMouseOver() {
      // console.log("鼠标悬停在元素上");
    },
    handleMouseOut() {
      // console.log("鼠标移出了元素");
    },
    clicktilte(e) {
      if (e == 1) {
        this.$router.push({ path: "/" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 2) {
        this.$router.push({ path: "/tempView" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 3) {
        this.$router.push({ path: "/productView" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 4) {
        this.$router.push({ path: "/about" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 5) {
        this.$router.push({ path: "/NewsView" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 6) {
        this.$router.push({ path: "/helpView" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 7) {
        this.$router.push({ path: "/contactView" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 8) {
        window.open("https://www.zlygu.com/", "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("../common/style.css");
.tab:hover {
  background: #2b22d2;
  width: 2rem;
  border-radius: 0px 0.6rem 0px 0px;
  .title {
    display: block !important;
  }
}
.item:hover img {
  animation: translate2 0.5s ease;
}
.itemed {
  background: #2b22d2;
}
.tab {
  background: rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100vh;
  width: 0.8rem;
  cursor: pointer;

  .item {
    display: flex;
    align-items: center;
    height: 0.72rem;
    img {
      width: 0.24rem;
      height: 0.24rem;
      margin: 0 0 0 0.28rem;
    }
    .title {
      color: #fff;
      font-family: Microsoft YaHei UI;
      font-size: 0.18rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 0.72rem;
      height: 0.23rem;
      margin-left: 0.45rem;
      margin-right: 0.31rem;
    }
  }
}
</style>