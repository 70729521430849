let deviceWidth
setHtmlFontSize()
if (window.addEventListener) {
  window.addEventListener('resize', function () {
    setHtmlFontSize()
  }, false)
}
function setHtmlFontSize () {
  if (document.documentElement.clientWidth > 750) {
    deviceWidth = document.documentElement.clientWidth > 1920 ? 1920 : document.documentElement.clientWidth
    document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + deviceWidth / 19.20 + 'px !important'
  } else {
    // document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + 100 + 'px !important'
  }
}
