import { createApp } from 'vue'
import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入全局css
import './assets/total.css'
// 引入全局rem.js
import './assets/rem'

// 关于我们页面鼠标滑动内容
import dragscroll  from '@/utils/directives'

import axios from 'axios'
const app = createApp(App) // 将默认改写为这样
app.provide('$axios', axios)

// axios
// 在main.js中 导入 axios，在组件中使用时不需要再进行导入
// import axios from 'axios'
// 全局配置 axios 的请求根路径
axios.defaults.baseURL = ''
// 把axios挂载到Vue的原型上
// Vue.prototype.$http = axios
// 在每个vue组件中要发起请求，直接调用this.$http.xxx
// 缺点：无法实现 api 的复用s

//将公共方法挂载this
// import fun from './common/common.js'
// Vue.prototype.$fun = fun;
createApp(App).use(store).use(router).use(ElementPlus).directive( 'dragscroll',dragscroll ).provide('$axios',axios).mount('#app')
