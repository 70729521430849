import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/tempView',
    name: 'tempView',
    component: () => import('../views/tempView.vue')
  },
  {
    path: '/NewsView',
    name: 'NewsView',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/NewsInfo',
    name: 'NewsInfo',
    component: () => import('../views/NewsInfo.vue')
  },
  {
    path: '/productView',
    name: 'productView',
    component: () => import('../views/productView.vue')
  },
  {
    path: '/helpView',
    name: 'helpView',
    component: () => import('../views/helpView.vue')
  },
  {
    path: '/contactView',
    name: 'contactView',
    component: () => import('../views/contactView.vue')
  },
  {
    path: '/scroll',
    name: 'scroll',
    component: () => import('../views/scroll.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
