<template>
  <div>
    <tou :data="1"></tou>
    <div
      @click="talk"
      style="
        position: fixed;
        bottom: 0;
        right: 0.1rem;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 1.8rem;
      "
    >
      <div
        style="
          color: #5e3b1b;
          font-size: 0.14rem;
          background: #fff;
          padding: 0.05rem 0.1rem;
          border-radius: 0.2rem;
          display: flex;
          align-items: center;
          border: 1px solid #5e3b1b;
          box-shadow: 2px 2px 4px #ccc;
          animation: anmite2 4s infinite ease;
          opacity: 0;
        "
      >
        <img
          src="../assets/home/talk.png"
          style="width: 0.26rem; height: 0.26rem"
          alt=""
        />{{ listtitle }}
      </div>
      <!-- <a href="https://w102.ttkefu.com/k/linkurl/?t=8F5HJI7" target="_blank"> -->
      <img
        src="../assets/hi.gif"
        alt="Animated GIF"
        style="width: 1.2rem; height: 1.2rem"
      />
      <!-- </a> -->
    </div>

    <div class="botp">
      山东优谷有鹿数字科技有限公司
      <span style="text-decoration-line: underline"
        >鲁ICP备 17020511号-2 Copyrigt</span
      >
      版权所有：优谷有鹿
    </div>
    <!-- <div id="fullpage"> -->
    <div>
      <div class="section one">
        <div class="shubiao">
          <img
            src="../assets/home/shubiao.png"
            alt=""
            style="
              width: 0.5rem;
              height: 0.5rem;
              animation: translate 2s infinite linear;
            "
          />
          <p>向下滑动</p>
        </div>
        <img :src="back1" alt="" style="width: 100%; height: 100vh" />
        <div
          style="
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
          "
        >
          <transition name="dmc" mode="out-in">
            <div class="title">做有生命力的品牌</div>
          </transition>
          <div
            style="
              width: 1.2rem;
              height: 0.01rem;
              background: rgba(255, 255, 255, 0.5);
              margin: auto;
            "
          ></div>
          <p class="title2">用探索世界的方式来探索品牌价值</p>
          <p class="title3">
            Exploring brand value through exploring the world
          </p>
        </div>
      </div>
      <div class="section two" :style="'background-image: url(' + back2 + ')'">
        <div class="top">
          <div>
            <img
              src="../assets/home/template website.png"
              alt=""
              style="width: 5.56rem; height: auto"
            />
            <p class="title">模板建站 多快好省</p>
          </div>
          <div class="Dmore" style="position: relative">
            <div class="more" style="" @click="liaojie(1)">了解更多 ▷</div>
            <div class="more-hover" style="" @click="liaojie(1)">
              了解更多 ▷
            </div>
          </div>
        </div>
        <div class="content">
          <div
            class="item"
            v-for="(item, index) in temlist"
            :key="index"
            @click="jumptemp(item)"
          >
            <img
              :src="item.image"
              alt=""
              style="width: 2.56rem; height: 2.3rem"
            />
            <p
              style="
                color: #fff;
                font-size: 0.26rem;
                background: linear-gradient(
                  180deg,
                  rgba(43, 34, 210, 0) 0%,
                  #2b22d2 100%
                );
              "
            >
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="section three"
        :style="'background-image: url(' + back2 + ')'"
      >
        <div class="top">
          <div>
            <img
              src="../assets/home/About us.png"
              alt=""
              style="width: 2.94rem; height: auto"
            />
            <p class="title">关于优谷有鹿</p>
          </div>
        </div>
        <div class="content">
          <div class="left" style="">
            <div class="zhezhao">
              <p class="p1">我们是做什么的</p>
              <p class="p2">
                山东优谷有鹿数字科技有限公司是一家专注于模版网站业务的创新型公司。我们的使命是为企业提供高质量、独特的模版网站。我们的团队由一群富有创意和技术才能的专业人员组成，不断追求设计美学和用户体验的极致。
                着重于品牌效应和品牌思维，不断精进，做到极致；以时间为轴，提高企业品牌的认同感；以共赢为线，塑造企业品牌的深耕和迭代。优谷有鹿总部设在青岛，以品牌网站、品牌设计、品牌开发作为核心产品矩阵。先后为：海尔、长虹佳华、双星、盛京银行、中国供销社、品牌西海岸、亚非欧海外仓等多个海内外知名企业、中国五百强注入了新的生命力。致力于提升客户在海内外的品牌影响力，把“引领变革，共存共赢，构建品牌价值最大化”做为核心价值观，创意与实效并存，别具匠心，不凡创想，启发品牌新活力。
              </p>
            </div>
          </div>
          <div class="right">
            <div class="item right1" style="">
              <div class="zhezhao">
                <p class="p1">好看的UI</p>
                <p class="p2">Beautiful UI</p>
              </div>
            </div>
            <div class="item right2" style="">
              <div class="zhezhao">
                <p class="p1">好玩的UX</p>
                <p class="p2">Fun UX</p>
              </div>
            </div>
            <div class="item right3" style="">
              <div class="zhezhao">
                <p class="p1">好用的官网</p>
                <p class="p2" style="">A user-friendly official website</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section four" :style="'background-image: url(' + back2 + ')'">
        <div class="top">
          <div>
            <img
              src="../assets/home/news.png"
              alt=""
              style="width: 1.65rem; height: auto"
            />
            <p class="title">新闻动态</p>
          </div>
          <div style="position: relative">
            <div class="Dmore" style="position: relative">
              <div class="more" style="" @click="liaojie(2)">了解更多 ▷</div>
              <div class="more-hover" style="" @click="liaojie(2)">
                了解更多 ▷
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="con-top">
            <img :src="newsimg" alt="" style="width: 4rem; height: 2.5rem" />
            <div class="top-left" style="margin-left: 0.6rem">
              <div class="title">
                <div class="title-p1" style="">
                  {{ newstitle1 }}
                  <div class="xian" style=""></div>
                </div>
                <p style="font-size: 0.14rem; width: 1.28rem">
                  {{ newsdate }}
                </p>
              </div>
              <div class="text">
                {{ newssubtitle1 }}
              </div>
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background: rgba(255, 255, 255, 0.25);
            "
          ></div>
          <div class="bottom">
            <div v-for="(item, index) in newslist" :key="index">
              <div class="item" v-if="index > 0">
                <div class="item-d">
                  <div class="title">
                    <p class="title-p1">{{ item.title }}</p>
                  </div>
                  <p class="text">
                    {{ item.subtitle }}
                  </p>
                  <div
                    style="
                      width: 1.08rem;
                      background: rgba(255, 255, 255, 0.5);
                      height: 1px;
                      margin-top: 0.4rem;
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section five" :style="'background-image: url(' + back2 + ')'">
        <div class="top">
          <div>
            <img
              src="../assets/home/Cooperative Partner.png"
              alt=""
              style="width: 6.77rem; height: auto"
            />
            <p class="title">合作伙伴</p>
          </div>
        </div>
        <div class="content">
          <div class="item" v-for="(item, index) in logolist" :key="index">
            <img
              :class="count == index ? 'imgshan' : ' '"
              :src="item"
              alt=""
              style="width: 2.2rem; height: 0.91rem; margin: auto"
            />
          </div>
        </div>
      </div>
      <div class="section six" :style="'background-image: url(' + back2 + ')'">
        <div>
          <div class="top">
            <div>
              <img
                src="../assets/home/Contact Us.png"
                alt=""
                style="width: 3.52rem; height: auto"
              />
              <p class="title">联系我们</p>
            </div>
          </div>
          <div class="content">
            <div class="left">
              <!-- <Transition name="fade"> -->
              <img
                class="wuxian"
                src="../assets/home/6img.png"
                alt=""
                style="
                  width: 3.38rem;
                  height: 1.29rem;
                  margin-left: 0.89rem;
                  margin-top: 0.84rem;
                "
              />
              <!-- </Transition> -->
              <div
                style="
                  color: #fff;
                  font-size: 0.26rem;
                  font-weight: 700;
                  margin-top: 0.6rem;
                  margin-bottom: 0.4rem;
                "
              >
                山东优谷有鹿数字科技有限公司
              </div>
              <div style="display: flex; align-items: center">
                <div class="erweima">
                  <img
                    src="../assets/home/erweima.png"
                    alt=""
                    style="width: 1.2rem; height: 1.2rem"
                  />
                </div>
                <div class="right" style="margin-left: 0.45rem">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      margin: 0.12rem 0;
                    "
                    v-for="(item, index) in botlist"
                    :key="index"
                  >
                    <img
                      :src="item.img"
                      alt=""
                      style="width: 0.28rem; height: 0.28rem"
                    />
                    <p
                      style="
                        color: rgba(255, 255, 255, 0.75);
                        font-size: 0.16rem;
                        margin-left: 0.12rem;
                        margin-right: 0.2rem;
                      "
                    >
                      {{ item.name }}
                    </p>
                    <p style="color: #fff; font-size: 0.16rem">
                      {{ item.text }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="con-right" style="">
              <p
                style="
                  color: #fff;
                  font-size: 0.26rem;
                  font-weight: 700;
                  margin-bottom: 0.4rem;
                "
              >
                在线留言
              </p>
              <div class="dinput">
                <div class="ddinput">
                  姓名：<input type="text" v-model="name" />
                </div>
                <div class="ddinput">
                  电话：<input
                    type="number"
                    v-model="phonenumber"
                    @change="phone()"
                  />
                  <p
                    v-if="!vernumber"
                    style="
                      color: #ff4f30;
                      position: absolute;
                      bottom: 0.36rem;
                      font-size: 0.14rem;
                    "
                  >
                    请输入正确的手机号
                  </p>
                </div>
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="请输入内容"
              ></textarea>
          
              <div class="Dmore" style="position: relative;width: 1.51rem;">
                <div class="more" style="margin-top: 0.8rem" @click="tijiao">提交内容 ▷</div>
                <div class="more-hover" style="" @click="tijiao">
                  提交内容 ▷
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import tou from "../components/tou";
// import fullpage from "fullpage.js";
// import "fullpage.js/dist/fullpage.css";
import "../common/style.css";
import { inject } from "vue";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import axios from "axios";


export default {
  components: {
    tou,
  },

  data() {
    return {
      show: false,
      name: "",
      phonenumber: "",
      vernumber: true,
      listtitle: "",
      list: ["点击和我对话哟~", "卓越的UI设计", "强大的定制能力"],
      listindex: 0,
      logolist: [
        require("../assets/logo/image 37.png"),
        require("../assets/logo/image 38.png"),
        require("../assets/logo/image 39.png"),
        require("../assets/logo/image 40.png"),
        require("../assets/logo/image 41.png"),
        require("../assets/logo/image 42.png"),
        require("../assets/logo/image 43.png"),
        require("../assets/logo/image 44.png"),
        require("../assets/logo/image 45.png"),
        require("../assets/logo/image 46.png"),
        require("../assets/logo/image 47.png"),
        require("../assets/logo/image 48.png"),
        require("../assets/logo/image 49.png"),
        require("../assets/logo/image 50.png"),
        require("../assets/logo/image 51.png"),
        require("../assets/logo/image 52.png"),
      ],
      botlist: [
        {
          img: require("../assets/home/bot1.png"),
          name: "公司地址",
          text: "山东省青岛市市北区敦化路136号西王大厦11层",
        },
        {
          img: require("../assets/home/bot2.png"),
          name: "联系电话",
          text: "0532-88984803",
        },
        {
          img: require("../assets/home/bot3.png"),
          name: "电子邮件",
          text: "service@zhonglianugu.com",
        },
      ],
      temlist: [],
      newslist: [],
      newstitle1: "",
      newssubtitle1: "",
      newsdate: "",
      newsimg: "",
      back1: "",
      back2: "",
      count: "",
      text: "着重于品牌效应和品牌思维，不断精进，做到极致；以时间为轴，提高企业品牌的认同感；以共赢为线，塑造企业品牌的深耕和迭代。中联优谷总部设在青岛，以品牌网站、品牌设计、品牌开发作为核心产品矩阵。先后为：海尔、长虹佳华、双星、盛京银行、中国供销社、品牌西海岸、亚非欧海外仓等多个海内外知名企业、中国五百强注入了新的生命力。致力于提升客户在海内外的品牌影响力，把“引领变革，共存共赢。",
    };
  },
  computed: {
    isValidPhoneNumber() {
      const regExp = /^[1][3,4,5,6,7,8,9][0-9]{9}$/; // 定义手机号的正则表达式
      console.log(this.phonenumber);
      if (regExp.test(this.phonenumber)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    listindex(listindex) {
      if (this.listindex < this.list.length) {
        this.listtitle = this.list[this.listindex];
        setTimeout(() => {
          //需要定时执行的代码
          this.listindex = this.listindex + 1;
          this.listtitle = this.list[this.listindex];
          console.log(this.listindex, this.listtitle);
        }, 4000);
      } else {
        this.listindex = 0;
        this.listtitle = this.list[this.listindex];
      }
    },
  },
  mounted() {
    // new fullpage("#fullpage", {
    //   sectionsColor: [],
    //   navigation: true,
    //   scrollBar: true,
    // });
    if (this.listindex < this.list.length) {
      this.listtitle = this.list[this.listindex];
      setTimeout(() => {
        //需要定时执行的代码
        this.listindex = this.listindex + 1;
        this.listtitle = this.list[this.listindex];
        console.log(this.listindex, this.listtitle);
      }, 4000);
    } else {
      this.listindex = 0;
      this.listtitle = this.list[this.listindex];
    }

    const $axios = inject("$axios");
    $axios
      .get("https://52996.icu/api/index/index")
      .then((resp) => {
        console.log(resp.data);
        this.temlist = resp.data.data.category;
        this.newslist = resp.data.data.news;
        this.newstitle1 = this.newslist[0].title;
        this.newssubtitle1 = this.newslist[0].subtitle;
        this.newsdate = this.newslist[0].create_time;
        this.newsimg = this.newslist[0].image;
        this.back1 = resp.data.data.background;
        this.back2 = resp.data.data.background2;
      })
      .catch((err) => {
        console.log(err);
      });
    setInterval(() => {
      if (this.count < this.logolist.length) {
        this.count++; // 每次间隔1秒自增1
      } else {
        this.count = 0;
      }
    }, 1000);
  },

  methods: {
    // showinfor(event) {
    //   // 处理鼠标滚轮滚动事件的逻辑
    //   console.log("鼠标滚轮滚动了");
    //   this.show = true;
    // },
    // 了解更多
    
    liaojie(e) {
      if (e == 1) {
        this.$router.push({ path: "/tempView" }).catch((err) => {
          console.log(err);
        });
      } else if (e == 2) {
        this.$router.push({ path: "/NewsView" }).catch((err) => {
          console.log(err);
        });
      }
    },
    talk() {
      var url = "https://w102.ttkefu.com/k/linkurl/?t=8F5HJI7"; //转向网页的地址;
      var name = "优谷有鹿客服"; //网页名称，可为空;
      var iWidth = 900; //弹出窗口的宽度;
      var iHeight = 600; //弹出窗口的高度;
      var iTop = (window.screen.availHeight - 30 - iHeight) / 2; //获得窗口的垂直位置;
      var iLeft = (window.screen.availWidth - 10 - iWidth) / 2; //获得窗口的水平位置;
      window.open(
        url,
        name,
        "height=" +
          iHeight +
          ",,innerHeight=" +
          iHeight +
          ",width=" +
          iWidth +
          ",innerWidth=" +
          iWidth +
          ",top=" +
          iTop +
          ",left=" +
          iLeft +
          ",toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no"
      );
    },
    phone() {
      console.log("开始验证", this.isValidPhoneNumber);
      if (this.isValidPhoneNumber) {
        console.log("手机号格式正确");
        this.vernumber = true;
      } else {
        console.log("手机号格式不正确");
        this.vernumber = false;
      }
    },
    jumptemp(item) {
      console.log(item.id);
      this.$router
        .push({ path: "/tempView", query: { id: item.id } })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交
    phone() {
      console.log("开始验证", this.isValidPhoneNumber);
      if (this.isValidPhoneNumber) {
        console.log("手机号格式正确");
        this.vernumber = true;
      } else {
        ElMessage.error('手机号格式不正确')
        console.log("手机号格式不正确");
        this.vernumber = false;
      }
    },
    open2() {
      ElMessageBox.confirm(
        "请填写完整信息！",
        "提示",
        {
          confirmButtonText: "好的",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // ElMessage({
          //   type: "success",
          //   message: "Delete completed",
          // });
        })
        .catch(() => {
          // ElMessage({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    open() {
      axios.post("https://52996.icu/api/index/message", {
        name: this.name,
        phonenumber: this.phonenumber,
        liuyan: this.liuyan,
      })
        .then((resp) => {
          ElMessage.success(resp.data.msg)
          this.initializationSet()
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
          ElMessage.success(err.data.msg)
        });
    },
    initializationSet() {
      this.name = ""
      this.phonenumber = ""
      this.liuyan = ""
    },
    tijiao() {
      if (
        this.name == "" ||
        this.phonenumber == "" ||
        this.vernumber == false
      ) {
        this.open2();
      } else {
        this.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .fp-watermark {
  display: none !important;
}
.three:hover .top {
  animation: translate3 0.5s ease;
}
.section {
  height: 100vh;
  //background: url(../assets/home/back2.png);
  background-size: 100% 100vh;
  padding: 1.5rem 0;
  box-sizing: border-box;
  .top:hover {
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 14.4rem;
    margin: 0 auto;

    .title {
      color: #fff;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
      font-family: Microsoft YaHei UI;
      font-size: 0.36rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: justify;
      margin-top: 0.1rem;
    }
  }
  .content {
    width: 14.4rem;
    margin: 0 auto;
  }
  .more-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: transparent;
    color: #fff;
    clip-path: polygon(0 0, 20% 0, 0% 100%, 0 100%);
    transition: clip-path 0.8s cubic-bezier(0.18, 0.78, 0.31, 0.92);
    cursor: pointer;
    font-family: Microsoft YaHei UI;
    font-size: 0.18rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.42rem;
  }
  .Dmore:hover .more-hover {
    background-color: #fff;
    color: #111;
    width: 1.52rem;
    height: 0.42rem;
    // clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .more {
    border: 0.01rem solid #fff;
    width: 1.5rem;
    height: 0.4rem;
    color: #fff;
    font-family: Microsoft YaHei UI;
    font-size: 0.18rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.4rem;
    cursor: pointer;
    text-align: center;

  }
}
.shubiao {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  z-index: 2;
  p {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Microsoft YaHei UI;
    font-size: 0.12rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.05rem;
  }
  img {
  }
}
.botp {
  position: fixed;
  bottom: 0.5rem;
  left: 2.4rem;
  z-index: 2;
  color: #fff;
  font-family: Microsoft YaHei UI;
  font-size: 0.14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.2rem;
  // text-decoration-line: underline;
}
.one {
  position: relative;
  padding: 0;
  video {
    width: 100%;
    height: 100vh;
  }
  .title {
    color: #fff;
    text-align: center;
    font-family: Alimama FangYuanTi VF;
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 4.05rem;
    margin-bottom: 0.8rem;
    animation: anmite1 1s ease;
    font-family: my-self-font;
  }
  .title2 {
    color: #fff;
    text-align: center;
    font-family: Microsoft YaHei UI;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.8rem;
    margin-bottom: 0.1rem;
    animation: anmite1 1s ease;
  }
  .title3 {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Microsoft YaHei UI;
    font-size: 0.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    animation: anmite1 1s ease;
  }
}
.two {
  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    .item:hover img {
      transform: scale(1.2);
      transition: transform 0.5s linear;
      transition-delay: 0.1s;
    }
    .item {
      width: 2.56rem;
      height: 2.3rem;
      position: relative;
      margin-bottom: 0.4rem;
      overflow: hidden;
      border-radius: 0.1rem;
      cursor: pointer;
      p {
        position: absolute;
        bottom: 0;
        text-align: center;
        border-radius: 0.1rem;
        width: 100%;
        line-height: 0.76rem;
      }
    }
  }
}
.three {
  .content {
    width: 14.4rem;
    height: 6rem;
    display: flex;
    margin-top: 0.5rem;
  }
  .zhezhao {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(43, 34, 210, 0.85) 0%,
      rgba(43, 34, 210, 0) 100%
    );
  }
  .left {
    width: 72%;
    height: 100%;
    background: url(../assets/home/back3.png);
    background-size: 100% 6rem;
    cursor: pointer;
    .zhezhao {
      padding: 1.11rem 1.36rem;
      box-sizing: border-box;
    }

    p {
      color: #fff;
      font-family: Microsoft YaHei UI;
      font-size: 0.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.3rem; /* 100% */
      letter-spacing: 0.03rem;
    }
    .p2 {
      margin-top: 0.59rem;
      font-size: 0.16rem;
      font-weight: 400;
      letter-spacing: 0.016rem;
    }
  }
  .right {
    width: 28%;
    height: 100%;
    cursor: pointer;

    .item {
      height: 33.34%;
      .zhezhao {
        padding: 0.5rem;
        box-sizing: border-box;
      }
      p {
        color: #fff;
        font-family: Microsoft YaHei UI;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 700;
        line-height: 0.3rem; /* 125% */
        text-align: justify;
      }
      .p1 {
        letter-spacing: 0.024rem;
      }
      .p2 {
        font-size: 0.14rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .right1 {
      background: url(../assets/home/right1.png);
      background-size: 100% 100%;
    }
    .right2 {
      background: url(../assets/home/right2.png);
      background-size: 100% 100%;
    }
    .right3 {
      background: url(../assets/home/right3.png);
      background-size: 100% 100%;
    }
  }
}
.four {
  .con-top {
    margin-left: 3.8rem;
    display: flex;
    align-items: end;
    padding-bottom: 0.4rem;
    cursor: pointer;
    img:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease;
    }
    .text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
  }
  .top-left:hover .title .title-p1 .xian {
    animation: xian 0.5s linear;
    opacity: 1;
  }
  .xian {
    height: 1px;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    opacity: 0;
    margin-top: 0.1rem;
  }
  .title {
    color: #fff;
    font-family: Microsoft YaHei UI;
    font-size: 0.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title-p1 {
    width: 2.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text {
    color: rgba(255, 255, 255, 0.75);
    text-align: justify;
    font-family: Microsoft YaHei UI;
    font-size: 0.14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.24rem; /* 171.429% */
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    .item {
      width: 3.3rem;
      height: 2.2rem;
      cursor: pointer;
    }
    .item-d {
      width: 3rem;
      height: 2rem;
      margin: 0 auto;
    }
    .item:hover .item-d {
      transform: scale(1.05);
      transition: transform 0.3s ease;
    }
    .text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
  }
}
.five {
  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // border: 1px solid rgba(255, 255, 255, 0.25);
    margin-top: 0.47rem;
    .item {
      border: 1px solid rgba(255, 255, 255, 0.25);
      cursor: pointer;
      width: 3.56rem;
      height: 1.32rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      img {
        opacity: 0.3;
      }
      .imgshan {
        animation: opacity1 2s ease infinite;
        // box-shadow: 10px 10px 10px #fff;
        // box-shadow: 2px 3px 10px 10px rgba(255, 255, 255, 0.5);
      }
    }
    .item:hover img {
      // transform: scale(1.2);
      // transition: transform 0.5s ease;
    }
  }
}
.six:hover .wuxian {
  transform: rotateY(360deg);
}
.six {
  /* 下面我们会解释这些 class 是做什么的 */
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .wuxian {
    transform-style: preserve-3d;
    perspective: 1000px; /* 可以根据需要调整透视效果的强度 */
    transform: rotateY(0deg);
    transition: transform 1s; /* 可以根据需要调整过渡的时间和效果 */
  }
  .content {
    width: 14.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 50%;
      height: 100%;
    }
    .con-right {
      color: #fff;
      font-family: "Microsoft YaHei UI";
      font-size: 0.18rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 33%;
      .dinput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .ddinput {
        width: 45%;
        height: 0.44rem;
        background: rgba(255, 255, 255, 0.05);
        border: none;
        border-bottom: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.19rem;
        box-sizing: border-box;
        position: relative;
      }
      input {
        width: 68%;
        height: 100%;
        background: rgba(255, 255, 255, 0);
        border: none;
        color: #fff;
        font-family: "Microsoft YaHei UI";
        font-size: 0.18rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      textarea {
        width: 99%;
        height: 2.32rem;
        background: rgba(255, 255, 255, 0.05);
        border: none;
        border-bottom: 1px solid #fff;
        margin-top: 0.4rem;
        padding: 0.1rem 0.19rem;
        box-sizing: border-box;
        color: #fff;
        font-family: "Microsoft YaHei UI";
        font-size: 0.18rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      textarea::placeholder {
        color: #fff;
      }
    }
  }
}
// 去除自带的增减按钮
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>