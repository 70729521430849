<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
.el-pagination {
  justify-content: center; //居中
  //float: left;居左
  //float: right;居右
}
:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #2b22d2 !important; //修改默认的背景色
}
:deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: #fff;
}
:deep(.el-button--primary.is-active, .el-button--primary:active) {
  background: rgba(255, 255, 255, 0.2) !important ;
  border-color: rgba(255, 255, 255, 0.2) !important;
}
:deep(.el-pagination.is-background .btn-next:disabled) {
  background: rgba(255, 255, 255, 0.2) !important ;
  border-color: rgba(255, 255, 255, 0.2) !important;
}
::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #fff;
}
::v-deep .el-pagination button {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #fff;
}
</style>
